import { endpoint } from "../utils/constants";

export async function loginApi({ username, password }) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: username, password }),
  };

  try {
    const response = await fetch(`${endpoint}/auth/sign_in`, options);

    const result = await response.json();

    return result;
  } catch (error) {
    throw error;
  }
}

export async function logoutApi(token) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
  };

  try {
    const response = await fetch(
      `${endpoint}/auth/sign_out?access_token=${token}`,
      options
    );
    const result = response;

    return result;
  } catch (error) {
    console.log(error);
  }
}
