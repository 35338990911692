import React from "react";
import DataTable from "react-data-table-component";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";

export default function CustomDataTable({
  columns,
  data,
  isLoading,
  title,
  filterColName,
  filterPlaceholder,
  onChangePage,
  onChangePageSize,
  totalRows,
}) {
  const customStyles = {
    table: {
      style: {
        // marginTop: 10,
        backgroundColor: "whitesmoke",
      },
    },
    header: {
      style: {
        backgroundColor: "whitesmoke",
      },
    },
    responsiveWrapper: {
      style: {
        borderRadius: 20,
      },
    },
    rows: {
      style: {
        color: "var(--text-md-color)",
        fontSize: 12,
        // margin: "5px 0",
      },
    },
    headRow: {
      style: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        // backgroundColor: "var(--main-bg-color)",
        // color: "white",
        // fontSize: 14,
        // borderRadius: 20,
        fontSize: 13,
        fontWeight: "bold",
      },
    },

    pagination: {
      style: {
        marginTop: 15,
        borderRadius: 20,
        // boxShadow: "0px 0px 10px var(--container-shadow-color)",
      },
    },
  };
  const [searchedText, setSearchedText] = React.useState("");

  // const filterData = data?.filter((item) =>
  //   item[filterColName]
  //     ?.toLowerCase()
  //     .includes(searchedText?.toLocaleLowerCase())
  // );

  return (
    <DataTable
      title={title}
      className="custom-dt"
      data={data}
      columns={columns}
      customStyles={customStyles}
      progressPending={isLoading}
      progressComponent={<CircularProgress color="grey" />}
      selectableRows
      responsive
      //expandableRows
      // subHeader
      // subHeaderComponent={
      //   <SearchComponent
      //     setSearchedText={setSearchedText}
      //     searchedText={searchedText}
      //     placeholder={filterPlaceholder}
      //   />
      //}
      pagination
      paginationServer
      onChangeRowsPerPage={onChangePageSize}
      paginationTotalRows={totalRows}
      onChangePage={onChangePage}
      highlightOnHover
      selectableRowsHighlight
      onColumnOrderChange={(cols) => console.log(cols)}
      //onSelectedRowsChange={({ selectedRows }) => handleSelected(selectedRows)}
    />
  );
}

function SearchComponent({ searchedText, setSearchedText, placeholder }) {
  return (
    <input
      placeholder={`Buscar${placeholder ? ` ${placeholder}` : ""}...`}
      className="form-control "
      style={{ width: 300 }}
      type="search"
      autoComplete="new-password"
      value={searchedText}
      onChange={(e) => {
        setSearchedText(e.target.value);
      }}
    />
  );
}
