import { endpoint } from "./constants";

async function request({ route, token, method, data, customParams }) {
  let res, result;
  let options = {};

  try {
    switch (method) {
      case "POST":
        options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(data),
          //...customParams,
        };

        break;
      case "PUT":
        options = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(data),
          //...customParams,
        };
        break;

      default:
        options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          // mode: "cors",
          //...customParams,
        };
        break;
    }

    let entry;
    let key, value;
    let queryParams = "";

    if (customParams) {
      for (entry of Object.entries(customParams)) {
        key = entry[0];
        value = entry[1];
        queryParams += `${key}=${value}&`;
      }
    }

    let url = `${endpoint}/${route}?${queryParams}`;

    res = await fetch(url, options);
    result = await res.json();

    return result;
  } catch (error) {
    throw error;
  }
}

export { request };
