import React from "react";
import useAuth from "../hooks/useAuth";
import { logoutApi } from "../api/auth";
import { GlobalContext } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { TopBanner } from "../components/TopBanner";
import "./css/AccountScreen.css";
import Userimg from "../media/user.png";

export default function AccountScreen({ setToken }) {
  const { logout, auth } = useAuth();
  const navigate = useNavigate();
  const { setCurrentLocation } = React.useContext(GlobalContext);
  const handleLogout = async () => {
    try {
      const response = await logoutApi(auth.token);

      if (response) {
        logout();
        setCurrentLocation("/");
        navigate("/");
        setToken(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const personalInfo = {
    Nombre: auth.user.profile.first_name,
    Email: auth.user.email,
    Apellido: auth.user.profile.last_name,
    Teléfono: auth.user.profile.phone_number || "Sin especificar",
  };

  const userInfo = {
    Usuario: auth.user.email,
    "Última sesión": auth.user.last_sign_in_at,
    Rol: auth.user.profile.role_name,
    Contraseña: "********",
    Telefono: auth.user.profile.phone_number || "Sin especificar",
  };

  return (
    <React.Fragment>
      <TopBanner sectionName={"Cuenta"} />
      <div className="Account-container">
        <div className="Account-section Acount-section--first">
          <img src={Userimg} alt="user" />
          <div className="Account-section-content">
            <p>{`${auth.user.profile.first_name} ${auth.user.profile.last_name} `}</p>
          </div>
        </div>
        <div className="Account-section">
          <div className="Account-section-header">
            <h5 className="Account-section-title">Información Personal</h5>
            {/* <span>Editar</span> */}
          </div>
          <div className="Account-section-content">
            {Object.entries(personalInfo).map((item) => (
              <div className="Account-section-content-item">
                <p>{item[0]}</p>
                <p>{item[1]}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="Account-section">
          <div className="Account-section-header">
            <h5 className="Account-section-title">Información de usuario</h5>
            {/* <span>Editar</span> */}
          </div>
          <div className="Account-section-content">
            {Object.entries(userInfo).map((item) => (
              <div className="Account-section-content-item">
                <p>{item[0]}</p>
                <p>{item[1]}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="Account-section">
          <a href="#" onClick={() => handleLogout()}>
            Cerrar Sesión
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
