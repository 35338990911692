import React from "react";
import {
  FiTruck,
  FiBell,
  FiUser,
  FiZap,
  FiHome,
  FiFileText,
  FiUsers,
  FiMap,
} from "react-icons/fi";

const GlobalContext = React.createContext({});

const defaultMenuOptions = [
  {
    label: "Dashboard",
    isAdmin: false,
    icon: FiHome,
    active: true,
    navigateTo: "/",
  },
  {
    label: "Notificaciones",
    isAdmin: false,
    icon: FiBell,
    active: false,
    navigateTo: "/notifications",
  },
  {
    label: "Eventos",
    isAdmin: false,
    icon: FiZap,
    active: false,
    navigateTo: "/events",
  },

  {
    label: "Geocercas",
    isAdmin: false,
    icon: FiMap,
    active: false,
    navigateTo: "/geofences",
  },
  {
    label: "Usuarios",
    isAdmin: true,
    icon: FiUsers,
    active: false,
    navigateTo: "/admin/users",
  },
  {
    label: "Unidades",
    isAdmin: false,
    icon: FiTruck,
    active: false,
    navigateTo: "/units",
  },
  {
    label: "Reportes",
    isAdmin: true,
    icon: FiFileText,
    active: false,
    navigateTo: "/reports",
  },
  {
    label: "Cuenta",
    isAdmin: false,
    icon: FiUser,
    active: false,
    navigateTo: "/account",
  },
];

function GlobalProvider({ children }) {
  const setCurrentLocation = (location) => {
    sessionStorage.setItem("currentLocation", location);
  };

  const getCurrentLocation = () => {
    return sessionStorage.getItem("currentLocation");
  };

  const getMenuOptions = () => {
    let currentLocation = getCurrentLocation();

    const newMenuOptions = defaultMenuOptions.map((option) => {
      if (option.navigateTo == currentLocation) {
        option.active = true;
      } else {
        option.active = false;
      }

      return option;
    });

    return newMenuOptions;
  };

  const contextValue = {
    setCurrentLocation,
    getCurrentLocation,
    getMenuOptions,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalProvider };
