import React, { useEffect, useState } from "react";
import { CustomModal } from "../CustomModal";
import { useFormik } from "formik";
import { createUserApi, updateUserApi } from "../../api/user";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";

import * as Yup from "yup";
import "./UserForm.css";

export default function UserForm({
  open,
  setOpen,
  userData,
  setUserData,
  setRequestToogle,
  token,
}) {
  const handleClose = () => {
    setOpen(false);
    setUserData(false);
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (() => {
      let role = "";
      switch (userData.role?.verbose_name) {
        case "Administrador":
          role = "intrant_admin";
          break;
        case "Fiscalizador":
          role = "intrant_auditor";
          break;
        case "Monitor":
          role = "intrant_monitor";
          break;
        default:
          break;
      }

      userForm.setFieldValue("role", role);
      userForm.setFieldValue("firstName", userData?.first_name);
      userForm.setFieldValue("lastName", userData?.last_name);
      userForm.setFieldValue("email", userData.email);
    })();
  }, [userData]);

  console.log("FROM USER FORM", userData);

  const userForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
      phone: "",
    },
    validateOnChange: false,
    validationSchema: Yup.object(valSchema(userData)),
    onSubmit: async (values, { resetForm }) => {
      let data = {
        email: values.email,
        password: values.password,
        phone: values.phone,
        // created_at: new Date().toISOString(),
        profile: {
          first_name: values.firstName,
          last_name: values.lastName,
          role_name: values.role,
        },
      };

      console.log(data);

      try {
        setIsLoading(true);
        if (!userData) {
          const response = await createUserApi(data, token);
        } else {
          const updateRes = await updateUserApi(userData.id, data, token);
        }
        setUserData({});
        setRequestToogle((state) => !state);
        resetForm({ values: "" });
        setOpen(false);
        setIsLoading(false);
      } catch (error) {
        setRequestToogle((state) => !state);
        console.log(error);
        setOpen(false);
        setIsLoading(true);
      }
    },
  });

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={
        !userData ? "Nuevo perfil de usuario" : "Edición de Perfil de Usuario"
      }
      isLoading={isLoading}
      handleClose={handleClose}
      form={userForm}
    >
      <form autoComplete="off" className="user-form-container">
        <div className="form-group">
          <TextField
            className="form-control my-4"
            autoFocus
            label="Nombre (s)"
            variant="outlined"
            color="warning"
            value={userForm.values.firstName}
            onChange={(e) =>
              userForm.setFieldValue("firstName", e.target.value)
            }
          />
          {userForm.errors.firstName && (
            <p className="user-form-error">{userForm.errors.firstName}</p>
          )}
        </div>

        <div className="form-group">
          <TextField
            className="form-control mb-3"
            label="Apellido (s)"
            variant="outlined"
            color="warning"
            value={userForm.values.lastName}
            onChange={(e) => userForm.setFieldValue("lastName", e.target.value)}
          />{" "}
          {userForm.errors.lastName && (
            <p className="user-form-error">{userForm.errors.lastName}</p>
          )}
        </div>
        {!userData && (
          <div className="form-group">
            <TextField
              className="form-control mb-3"
              label="Usuario"
              variant="outlined"
              color="warning"
              value={userForm.values.email}
              onChange={(e) => userForm.setFieldValue("email", e.target.value)}
            />
          </div>
        )}
        {userForm.errors.email && (
          <p className="user-form-error">{userForm.errors.email}</p>
        )}
        {!userData && (
          <div className="form-group">
            <TextField
              className="form-control mb-3"
              label="Contraseña (6 digits)"
              variant="outlined"
              type="password"
              color="warning"
              value={userForm.values.password}
              onChange={(e) =>
                userForm.setFieldValue("password", e.target.value)
              }
            />
          </div>
        )}
        {userForm.errors.password && (
          <p className="user-form-error">{userForm.errors.password}</p>
        )}
        <div className="form-group">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel color="warning">Rol</InputLabel>
            <Select
              className="form-control"
              label="Rol"
              color="warning"
              value={userForm.values.role || "intrant_monitor"}
              onChange={(e) => userForm.setFieldValue("role", e.target.value)}
            >
              <MenuItem value={"intrant_monitor"}>Monitor</MenuItem>
              <MenuItem value={"intrant_auditor"}>Fiscalizador</MenuItem>
              <MenuItem value={"intrant_admin"}>Administrador</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* <div className="form-group">
            <label>Teléfono</label>
            <input
              type={"text"}
              className="form-control"
              placeholder="(809) 000-0000"
              value={userForm.values.phone}
              onChange={(e) => userForm.setFieldValue("phone", e.target.value)}
            />
          </div> */}
      </form>
    </CustomModal>
  );
}

function valSchema(userData) {
  const messages = {
    required: "Favor complete este campo",
  };

  if (userData) return {};

  return {
    email: Yup.string()
      .email("El email no es válido!")
      .required(messages.required),

    firstName: Yup.string().required(messages.required),
    lastName: Yup.string().required(messages.required),
    password: Yup.string()
      .required(messages.required)
      .min(6, "* mínimo  6 caractéres"),
  };
}
