import React from "react";
import { GoogleMap, Marker, LoadScript, Polygon } from "@react-google-maps/api";

function NotificationMap({
  customZoom,
  markerCoords,
  polygonCoords,
  style,
  polygonStyle,
}) {
  const mapContainerStyle = {
    height: style?.height || "45vh",
    width: style?.width || "100%",
    borderRadius: style?.borderRadius || 20,
    boxShadow: "0 10px 10px var(--container-shadow-color)",
  };

  const mapOptions = {
    gestureHandling: "greedy",
  };

  const polygonOptions = {
    fillColor: polygonStyle?.backgroundColor || "#66b050",
    fillOpacity: 0.3,
    strokeColor: polygonStyle?.borderColor || "#66b050",
    strokeOpacity: 0.6,
    strokeWeight: 2,
    // clickable: polygonDrawing,
    // draggable: polygonDrawing,
    // editable: polygonDrawing,
    // zIndex: 1,
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      language="es"
    >
      <GoogleMap
        // onLoad={handleLoad}
        mapContainerStyle={mapContainerStyle}
        center={markerCoords}
        // center={{ lat: 18.735693, lng: -70.162651 }}
        zoom={customZoom || 9}
        // onClick={onClick}
        options={mapOptions}
      >
        <Polygon path={polygonCoords} options={polygonOptions} />
        <Marker
          position={markerCoords}
          title={` Latitude ${markerCoords.lat}, Longitude ${markerCoords.lng}`}
        />
      </GoogleMap>
    </LoadScript>
  );
}

export { NotificationMap };
