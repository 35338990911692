import React, { useEffect, useState } from "react";
import moment from "moment";
import esLocale from "moment/locale/es";

import DataTable from "../components/Datatable";
import { TopBanner } from "../components/TopBanner";
import RegularMenuComponent from "../components/RegularMenu";
import UserForm from "../components/UserForm/UserForm";
import { SearchBar } from "../components/SearchBar";

import "./css/UserScreen.css";

import { getUsersApi } from "../api/user";
import useAuth from "../hooks/useAuth";

moment.locale("fr", [esLocale]);

export default function UserScreen({ setToken }) {
  const createUser = () => {
    setOpen(true);
  };

  const [requestToggle, setRequestToogle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});
  const [targetPage, setTargetPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const { auth, logout } = useAuth();
  const [searchedText, setSearchedText] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await getUsersApi(
          auth.token,
          targetPage,
          pageSize,
          searchParams
        );

        let arr = [];
        response.results.map((user, index) => {
          arr.push({
            id: user.user_id,
            email: user.email,
            firstName: user?.first_name,
            role: user?.role.verbose_name,

            lastSignInAt: user.last_sign_in_at,
            createdAt: moment(user.created_at).locale("es").fromNow(),
            action: (
              <RegularMenuComponent
                setOpen={setOpen}
                email={user.email}
                pos={index}
                data={response.results}
                setUserData={setUserData}
              />
            ),
          });
        });

        setIsLoading(false);
        setData(arr);
        setTotalRows(response?.info?.count);
      } catch (error) {
        if (JSON.parse(error?.message)?.error == "sessionTimeOut") {
          alert(JSON.parse(error.message)?.body || error);
          setToken(undefined);
          logout();
        }
      }
    })();
  }, [requestToggle]);

  const columns = [
    {
      name: "Usuario",
      selector: (row) => row.email,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
      reorder: true,
    },
    // {
    //   name: "Teléfono",
    //   selector: (row) => row.phone,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      name: "Última conexión",
      selector: (row) => row.lastSignInAt,
      sortable: true,
      reorder: true,
    },
    {
      name: "Creado",
      selector: (row) => row.createdAt,
      sortable: true,
      reorder: true,
    },
    {
      name: "Acciones",
      selector: (row) => row.action,
      sortable: true,
      reorder: true,
    },
  ];

  let searchedData = data.filter(
    (item) =>
      item.email?.toLowerCase().includes(searchedText.toLowerCase()) || item
  );

  console.log(data);

  return (
    <div className="user_container">
      <div>
        <TopBanner
          sectionName={"Usuarios"}
          onClick={createUser}
          isLoading={isLoading}
          bottonName={"Nuevo usuario"}
        />
      </div>
      <SearchBar
        searchItems={[]}
        setSearchedText={setSearchedText}
        searchedText={searchedText}
      />
      <div className="event_container-table">
        <DataTable
          columns={columns}
          data={searchedData}
          isLoading={isLoading}
          filterColName="firstName"
          filterPlaceholder={"usuario"}
          totalRows={totalRows}
          onChangePage={(page) => setTargetPage(page)}
          onChangePageSize={(pageSize) => setPageSize(pageSize)}
        />
      </div>
      <UserForm
        open={open}
        setOpen={setOpen}
        setRequestToogle={setRequestToogle}
        requestToggle={requestToggle}
        token={auth.token}
        userData={userData}
        setUserData={setUserData}
      />
    </div>
  );
}
