import React from "react";
import "./index.css";
import { useProSidebar } from "react-pro-sidebar";
import { FiMenu, FiChevronLeft } from "react-icons/fi";

function TopBanner({ sectionName, bottonName, onClick }) {
  const { toggleSidebar, collapseSidebar, collapsed } = useProSidebar();

  const winDimensions = () => {
    return { winWidth: window.innerWidth, winHeight: window.innerHeight };
  };

  return (
    <div className="topbanner_container">
      <div className="d-flex">
        {/* <span
          style={{ marginRight: 10 }}
          onClick={() => {
            let width = winDimensions().winWidth;
            if (width <= 575) {
              if (collapsed) {
                collapseSidebar();
                toggleSidebar();
              } else {
                toggleSidebar();
              }
            } else {
              collapseSidebar();
            }
          }}
        >
          {collapsed || winDimensions().winWidth <= 575 ? (
            <FiMenu className="toggle-icons" />
          ) : (
            <FiChevronLeft className="toggle-icons" />
          )}
        </span> */}
        <p>{sectionName}</p>
      </div>
      {onClick ? (
        <span className="btn topbaner_button" onClick={onClick}>
          {bottonName}
        </span>
      ) : null}
    </div>
  );
}

export { TopBanner };
