import React, { useState } from "react";
import "./css/LoginScreen.css";
import * as Yup from "yup";
import { useFormik } from "formik";

import { loginApi } from "../api/auth";
import useAuth from "../hooks/useAuth";

import CircularProgress from "@mui/material/CircularProgress";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { BsShieldLock } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Logo from "../media/logo-intrant.png";
import { Loader } from "../components/Loader";

export default function LoginScreen({ setToken }) {
  const { auth, login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [passwordHidden, setPasswordHidden] = useState(true);

  const loginForm = useFormik({
    initialValues: { username: "", password: "" },
    validateOnChange: false,
    validationSchema: Yup.object({
      username: Yup.string().required("User required"),
      password: Yup.string().required("Password required"),
    }),
    onSubmit: async (values) => {
      try {
        const { username, password } = values;
        setIsLoading(true);
        const response = await loginApi({ username, password });
        setIsLoading(false);

        if (response) {
          if (response.detail) {
            loginForm.setFieldError("username", response.detail[0].msg);
            loginForm.setFieldValue("password", "");
          } else {
            login({
              user: response.user,
              token: response.access_token,
            });

            setToken(response.access_token);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <div>
      {isLoading && <Loader height="40" width="40" color="#c3c3c3" />}
      <div className="login_container">
        <form className="login_form">
          <div className="LoginForm-banner">
            <img src={Logo} />
          </div>
          <div className="LoginForm-content">
            <div className="form-group">
              <HiOutlineUserCircle className="LoginForm-input-icon LoginForm-input-icon--username" />
              <input
                type="text"
                className="LoginForm-input LoginForm-input--username"
                placeholder="Nombre de usuario"
                value={loginForm.values.username}
                onChange={(e) =>
                  loginForm.setFieldValue("username", e.target.value)
                }
              />

              <span className="login_form--error">
                {loginForm.errors.username}
              </span>
            </div>
            <div className="form-group">
              {passwordHidden ? (
                <AiOutlineEye
                  onClick={() => setPasswordHidden(!passwordHidden)}
                  className="LoginForm-input-icon LoginForm-input-icon--vision"
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setPasswordHidden(!passwordHidden)}
                  className="LoginForm-input-icon LoginForm-input-icon--vision"
                />
              )}
              <BsShieldLock className="LoginForm-input-icon LoginForm-input-icon--pasword" />
              <input
                type={passwordHidden && "password"}
                placeholder="Contraseña"
                className="LoginForm-input LogiForm-input--password"
                value={loginForm.values.password}
                onChange={(e) =>
                  loginForm.setFieldValue("password", e.target.value)
                }
              />
              <span className="login_form--error">
                {loginForm.errors.password}
              </span>
            </div>
            <div className="form-group">
              <select className="LoginForm-input LoginForm-input--language ">
                <option>Español de República Dominicana</option>
              </select>
              <span className="login_form--error">
                {loginForm.errors.password}
              </span>
            </div>
          </div>

          <div className="form-group text-center">
            <button
              className="LoginForm-button"
              onClick={loginForm.handleSubmit}
              type="submit"
            >
              Inicio de sesión
            </button>
          </div>
          <div className="form-group text-center ">
            <a href="">Tienes problemas para iniciar sesión?</a>
          </div>
        </form>
      </div>
    </div>
  );
}
