import React from "react";
import "./index.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useProSidebar } from "react-pro-sidebar";
import { FiMenu, FiChevronLeft } from "react-icons/fi";
//import { UserIcon } from "../UserIcon";

export default function TopNavigation() {
  const { toggleSidebar, collapseSidebar, collapsed } = useProSidebar();

  const winDimensions = () => {
    return { winWidth: window.innerWidth, winHeight: window.innerHeight };
  };

  const [time, setTime] = React.useState(new Date());

  React.useEffect(() => {
    setInterval(() => setTime(new Date()), 1000);
  }, []);

  return (
    <Navbar className="topnavbar">
      <div
        style={{
          padding: "0 30px",
          margin: 0,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Navbar.Brand
          style={{ cursor: "pointer" }}
          onClick={() => {
            let width = winDimensions().winWidth;
            if (width < 768) {
              if (collapsed) {
                collapseSidebar();
                toggleSidebar();
              } else {
                toggleSidebar();
              }
            } else {
              collapseSidebar();
            }
          }}
        >
          {collapsed || winDimensions().winWidth < 768 ? (
            <FiMenu className="toggle-icons" />
          ) : (
            <FiChevronLeft className="toggle-icons" />
          )}
        </Navbar.Brand>
        {/* <p style={{ margin: 0, color: "grey", fontSize: 14 }}>
          {time.toLocaleTimeString()} Santo Domingo, República Dominicana
        </p> */}
        <div className="d-flex">{/* <UserIcon /> */}</div>
      </div>
    </Navbar>
  );
}
