import React, { useEffect, useState } from "react";
import { TopBanner } from "../components/TopBanner";
import DataTable from "../components/Datatable";
import { getUnitsApi } from "../api/unit";
import useAuth from "../hooks/useAuth";
import "./css/EventScreen.css";
import Map from "../components/Map3";
import { createClient } from "@supabase/supabase-js";
import { supabaseId, supabaseKeyAnon } from "../utils/constants";
import { FiInfo } from "react-icons/fi";
import { SearchBar } from "../components/SearchBar";
import { uniqBy } from "lodash";

export default function UnitScreen() {
  const { auth } = useAuth();
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestToggle] = useState(false);
  const [targetPage, setTargetPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [payloadRealtime, setPayload] = useState();
  const [movingMarkers, setMovingMarkers] = useState({});
  const [searchParams, setSearchParams] = useState({});

  const getCellStyle = (lastReport) => {
    if (lastReport === "desconectado") {
      return "red";
    } else if (lastReport === "hibernando") {
      return "orange";
    } else if (lastReport === "conectado") {
      return "green";
    }
  };

  const supabase = createClient(
    `https://${supabaseId}.supabase.co`,
    supabaseKeyAnon,
    {
      global: {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    }
  );
  supabase.realtime.setAuth(auth.token);

  useEffect(() => {
    supabase
      .channel("table-db-changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "unit_pos",
        },
        async (payload) => {
          const { imei } = payload.new;

          try {
            const { data, error } = await supabase
              .from("unit")
              .select("name")
              .eq("imei", imei);

            if (!error && data.length > 0) {
              payload.new.name = data[0].name;
            }

            setPayload(payload);
            const { id, latitude, longitude } = payload.new;

            setMovingMarkers((prevMarkers) => ({
              ...prevMarkers,
              [imei]: { lat: latitude, lng: longitude, name: payload.new.name },
            }));
          } catch (error) {
            console.error("Error fetching unit data:", error);
          }
        }
      )
      .subscribe();
  }, [auth.token, requestToggle, targetPage, pageSize]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let units = await getUnitsApi(
          auth.token,
          targetPage,
          pageSize,
          searchParams
        );
        setIsLoading(false);
        setData(units.results);
        setTotalRows(units?.info?.count);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [auth.token, requestToggle, targetPage, pageSize, searchParams]);

  useEffect(() => {
    const savedMarkers = localStorage.getItem("savedMarkers");
    if (savedMarkers) {
      setMovingMarkers(JSON.parse(savedMarkers));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("savedMarkers", JSON.stringify(movingMarkers));
  }, [movingMarkers]);

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Placa",
      selector: (row) => row.plate,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Imei",
      selector: (row) => row.imei,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div className={`oval-cell ${getCellStyle(row.status)}`}>
          {row.status}
        </div>
      ),
    },
    //{
    //  name: "Ultima Conexion",
     // selector: (row) => row.last_time,
     // sortable: true,
     // selectable: true,
     // reorder: true,
   // },
    {
      name: "Perfil",
      selector: (row) => `${row.profile.brand} - ...`,
      sortable: true,
      selectable: true,
      reorder: true,
      cell: (row) => (
        <div className="profile-cell">
          <FiInfo />
          <div className="additional-info-column">
            <p>
              <strong>Vin:</strong> {row.profile.vin}
            </p>
            <p>
              <strong>Tipo:</strong> {row.profile.vehicle_class}
            </p>
            <p>
              <strong>Placa:</strong> {row.profile.registration_plate}
            </p>
            <p>
              <strong>Marca:</strong> {row.profile.brand}
            </p>
            <p>
              <strong>Modelo:</strong> {row.profile.model}
            </p>
            <p>
              <strong>Año:</strong> {row.profile.year}
            </p>
            <p>
              <strong>Color:</strong> {row.profile.color}
            </p>
          </div>
        </div>
      ),
    },
  ];

  const searchItems = [
    {
      label: "Placa",
      name: "plate",
      type: "text",
    },
    {
      label: "Imei",
      name: "imei",
      type: "text",
    },
    // {
    //   label: "Estado",
    //   name: "status",
    //   type: "select",
    //   options: [
    //     {
    //       label: "conectado",
    //       value: "conectado",
    //     },
    //     {
    //       label: "hibernando",
    //       value: "hibernando",
    //     },
    //     {
    //       label: "desconectado",
    //       value: "desconectado",
    //     },
    //   ],
    // },
  ];

  return (
    <div>
      <TopBanner sectionName="Unidades" />
      <div className="dash-mapView mb-4">
        <Map movingMarkers={movingMarkers} />
      </div>
      <SearchBar
        searchItems={searchItems}
        setSearchParams={setSearchParams}
        mainFilter="name"
        placeholder={"Buscar por Nombre..."}
      />
      <div className="event_container-table">
        <DataTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          filterColName="name"
          filterPlaceholder={"unidad"}
          totalRows={totalRows}
          onChangePage={(page) => setTargetPage(page)}
          onChangePageSize={(pageSize) => setPageSize(pageSize)}
        />
      </div>
    </div>
  );
}
