import React from "react";
import "./ItemList.css";

function ItemList({ children, title }) {
  return (
    <div className="ItemList-container">
      <p>{title || "Title list"}</p>
      <ul>{children}</ul>
    </div>
  );
}

export { ItemList };
