import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from "@react-google-maps/api";
import TruckIcon from "./icons/truck.svg";

import "../screens/css/Map.css";

export default function Map({ onClick, movingMarkers }) {
  const mapContainerStyle = {
    height: "45vh",
    width: "100%",
    borderRadius: 20,
    boxShadow: "0 10px 10px var(--container-shadow-color)",
  };

  const [center] = useState({ lat: 18.735693, lng: -70.162651 });
  const zoom = 9;
  const mapOptions = {
    gestureHandling: "greedy",
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} language="es">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        onClick={onClick}
        options={mapOptions}
      >
        <MarkerClusterer options={{ imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m" }}>
          {(clusterer) =>
            Object.keys(movingMarkers).map((imei) => (
              <Marker
                key={imei}
                position={movingMarkers[imei]}
                icon={{
                  url: TruckIcon,
                  scaledSize: new window.google.maps.Size(40, 40),
                  labelOrigin: new window.google.maps.Point(20, 50),
                }}
                label={{
                  text: movingMarkers[imei].name,
                  className: "red-oval-cell",
                }}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  );
}
