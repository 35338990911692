import React, { useEffect, useState } from "react";
import "./index.css";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";

import Logo from "../../media/logo-intrant-full-white.png";

export default function SidebarNavigation(props) {
  const navigate = useNavigate();

  const { getCurrentLocation, setCurrentLocation, getMenuOptions } =
    React.useContext(GlobalContext);
  const { collapsed, collapseSidebar } = useProSidebar();

  const [menuOptions, setMenuOptions] = useState(getMenuOptions);

  const setItemActive = (label) => {
    let newArr = [];

    menuOptions.map((option) => {
      if (option.label === label) {
        option.active = true;
      } else {
        option.active = false;
      }

      newArr.push(option);
    });

    setMenuOptions(newArr);
  };

  return (
    <div className="sidebar-container">
      <Sidebar
        breakPoint="md"
        className="sidebar"
        rootStyles={{
          backgroundColor: "var(--main-bg-color)",
          border: "none",
          height: "100vh",
        }}
      >
        <div className="sidebar-header">
          <img
            src={Logo}
            style={{
              marginBottom: 10,
              width: 200,
              cursor: "pointer",
              marginLeft: 10,
            }}
            onClick={() => {
              setItemActive("Home");
              navigate("/");
            }}
          />
          {!collapsed && <p style={{ margin: 0 }}></p>}
        </div>

        <Menu
          className="menu"
          style={
            {
              // color: "rgba(89,46,2,0.6)",
              // fontWeight: 400,
            }
          }
          menuItemStyles={{
            button: ({ active }) => {
              return {
                backgroundColor: active && "var(--bg-color)",
                color: active ? "#075a79" : "#fff",
                boxSizing: "border-box",
                fontWeight: active ? "600" : "normal",
                ":hover": {
                  backgroundColor: active ? "var(--bg-color)" : "transparent",
                  // color: "black",
                  // fontWeight: active ? "bold" : "normal",
                  // transform: !active && "scale(1.01)",
                  textShadow: !active && "0 0 3px",
                },

                ":focus": {
                  outline: "none",
                },

                "b:nth-child(1)": active && {
                  position: "absolute",
                  top: "-5px",
                  width: "100%",
                  height: "10px",
                  backgroundColor: "var(--bg-color)",
                },

                ":after": active && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: 10,
                  // borderRadius: "50%",
                  backgroundColor: "var(--main-bg-color)",
                  borderBottomRightRadius: 10,
                  top: "-10px",
                  left: "0",
                },

                "b:nth-child(2)": active && {
                  position: "absolute",
                  bottom: "-10px",
                  width: "100%",
                  height: "10px",
                  backgroundColor: "var(--bg-color)",
                },
                ":before": active && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: 10,
                  // borderRadius: "50%",
                  backgroundColor: "var(--main-bg-color)",
                  borderTopRightRadius: 10,
                  bottom: "-10px",
                  left: "0",
                  zIndex: 1,
                },
                border: "none",

                // borderRight: active && "2px solid var(--main-bg-color)",
                // fontWeight: active && "bold",
                // marginLeft: 15,
                // marginRight: 15,
                // borderRadius: 8,
                paddingLeft: 20,

                // marginTop: 2,
                // marginBottom: 2,
              };
            },
            // icon: { color: "#1687A7" },
          }}
        >
          <section className="menu_div">
            {!collapsed && <SidebarSectionDiv text={"General"} />}
            {menuOptions
              .filter((item) => !item.isAdmin)
              .map((item, index) => (
                <MenuItem
                  title={`${item.label}`}
                  key={index}
                  active={item.active}
                  onClick={() => {
                    collapsed && collapseSidebar();
                    setItemActive(item.label);
                    setCurrentLocation(item.navigateTo);
                    navigate(item.navigateTo);
                  }}
                  icon={(() => {
                    let IconName = item.icon;
                    return (
                      <IconName
                        title={`${item.label}`}
                        color={item.active ? "black" : "#fff"}
                      />
                    );
                  })()}
                >
                  <b></b>
                  <b></b>
                  {item.label}
                </MenuItem>
              ))}
          </section>
          <section className="menu_div">
            {!collapsed && <SidebarSectionDiv text={"Administración"} />}
            {menuOptions
              .filter((item) => item.isAdmin)
              .map((item, index) => (
                <MenuItem
                  key={index}
                  active={item.active}
                  onClick={() => {
                    collapsed && collapseSidebar();
                    setItemActive(item.label);
                    setCurrentLocation(item.navigateTo);
                    navigate(item.navigateTo);
                  }}
                  icon={(() => {
                    let IconName = item.icon;
                    return <IconName color={item.active ? "black" : "#fff"} />;
                  })()}
                >
                  <b></b>
                  <b></b>
                  {item.label}
                </MenuItem>
              ))}
          </section>
        </Menu>
      </Sidebar>
    </div>
  );
}

function SidebarSectionDiv({ text }) {
  return (
    <p
      style={{
        paddingLeft: 15,
        fontSize: 14,
        color: "rgba(255,255,255,0.3)",
        transition: ".3s",
      }}
    >
      {text}
    </p>
  );
}

function SidebarIcon({ iconName }) {
  return React;
}
