import { createClient } from "@supabase/supabase-js";
import {
  supabaseId,
  supabaseKeyAnon,
  supabaseKeyAdmin,
} from "../utils/constants";

export const supabaseClient = createClient(
  `https://${supabaseId}.supabase.co`,
  supabaseKeyAnon
);

export const supabaseClientAdmin = createClient(
  `https://${supabaseId}.supabase.co`,
  supabaseKeyAdmin
);
