import { request } from "../utils/network";

async function getEventsApi(token, targetPage, pageSize, searchParams) {
  let urlParams = {
    page: targetPage,
    page_size: pageSize,
  };

  if (Object.entries(searchParams).length > 0) {
    urlParams.search_params = JSON.stringify(searchParams);
  }

  try {
    let events = await request({
      route: "events",
      method: "GET",
      token,
      customParams: urlParams,
    });
    return events;
  } catch (error) {
    console.log(error);
  }
}

async function createEventApi(token, data) {
  try {
    // let unitArr = [];

    // data.units?.forEach((unit) => {
    //   unitArr.push(unit.id);
    // });

    // data.units = [...unitArr];

    const response = await request({
      route: "events",
      token,
      method: "POST",
      data,
    });
  } catch (error) {
    console.log(error);
  }
}

export { createEventApi, getEventsApi };
