import React, { useEffect } from "react";
import "./css/HomeScreen.css";
import useAuth from "../hooks/useAuth";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { TopBanner } from "../components/TopBanner";
import { LineWave } from "react-loader-spinner";
import { ModalMessage } from "../components/ModalMessage";
import { supabaseClientAdmin } from "../api/supabase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function HomeScreen() {
  const { auth } = useAuth();

  const [notifications, setNotifications] = React.useState(undefined);
  const [units, setUnits] = React.useState(undefined);
  const [events, setEvents] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalMsgOpen, setIsmodalMsgOpen] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);

      let { data, error } = await supabaseClientAdmin.rpc("get_total_counts");
      let dashboardData = data[0];

      console.log(dashboardData);

      setIsLoading(false);
      setNotifications(dashboardData.notification_count);
      setUnits(dashboardData.unit_count);
      setEvents(dashboardData.event_count);
    })();
  }, []);

  const stats = [
    {
      title: "Unidades",
      score: units,
      caption: "Total",
      selectOptions: [],
    },
    {
      title: "Notificaciones",
      score: notifications,
      caption: "Total",
      selectOptions: [],
    },
    {
      title: "Eventos",
      score: events,
      caption: "Total",
      selectOptions: [],
    },
  ];

  const labels = ["Zona Zar", "", "", ""];
  const data = {
    labels,
    datasets: [
      {
        label: "Ultima semana",
        data: [41, 0, 0, 0],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="home-container">
      <div style={{ paddingLeft: 10 }}>
        <TopBanner sectionName="Dashboard" />
      </div>

      <div className="home-stats-container">
        {stats.map((stat) => (
          <div key={stat.title} className="home-stats-item">
            <h5 className="home-stat-item-title">{stat.title}</h5>
            <div className="home-stat-item-score">
              {/* <p></p> */}
              {!isLoading ? (
                <p>
                  {stat.score} {stat.title === "Unidades" && `-1500`}
                </p>
              ) : (
                <LineWave
                  height={72}
                  width={72}
                  wrapperStyle={{ marginLeft: 35 }}
                />
              )}
              <p>{stat.caption}</p>
            </div>
            <select>
              <option value="all">Todas</option>
            </select>
          </div>
        ))}
      </div>
      <div className="home-charts-container">
        <div className="home-charts-item home-charts--barchart">
          <h5 className="home-charts-item-title">Infracciones a Geocercas</h5>
          <div className="home-charts-item-content">
            <Bar options={{ maintainAspectRatio: true }} data={data} />
          </div>
        </div>
        <div className="home-charts-item home-charts--doughnut">
          <h5 className="home-charts-item-title"></h5>
          <div className="home-charts-item-content">
            <Bar options={{ maintainAspectRatio: true }} data={data} />
          </div>
        </div>
      </div>
      {auth.user.last_sign_in_at?.toLowerCase() === "nunca" && (
        <ModalMessage open={modalMsgOpen} setOpen={setIsmodalMsgOpen} />
      )}
    </div>
  );
}
