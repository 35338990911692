import React from 'react';

export default function ReportScreen() {

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <iframe
                width="100%"
                height="100%"
                src="https://lookerstudio.google.com/embed/reporting/a5698178-9af1-4d63-ad77-aa2c0a7d2306/page/AAEVD"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen

            ></iframe>
        </div>
    );
}