import React from "react";
import "./index.css";
import { MdSearch } from "react-icons/md";
import { useFormik } from "formik";

function getInitialValues(arr, mainFilter) {
  let initialValues = {};
  arr.forEach((item) => {
    if (item.type != "text") {
      initialValues[item.name] = item.options[0]?.value;
    }
  });

  initialValues[mainFilter] = "";

  return initialValues;
}

function SearchBar({
  searchItems,
  mainFilter,
  placeholder,
  setSearchParams,
  searchTimeOut,
}) {
  const searchForm = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(searchItems, mainFilter),
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      let data = {};

      Object.entries(values).map((value) => {
        data[value[0]] = value[1];
      });

      setSearchParams(data);
      if (values[mainFilter] == "") {
        resetForm();
      }
    },
  });

  React.useEffect(() => {
    const delayInput = setTimeout(() => {
      searchForm.handleSubmit();
    }, searchTimeOut || 1000);

    return () => clearTimeout(delayInput);
  }, [searchForm.values[mainFilter]]);

  return (
    <div className="search-bar">
      <div className="search-bar-group first-group">
        <label>¿Qué estás buscando?</label>
        <input
          className="search-bar-input"
          type="search"
          value={searchForm.values[mainFilter]}
          onChange={(e) => searchForm.setFieldValue(mainFilter, e.target.value)}
          placeholder={placeholder}
        />
      </div>
      {searchItems
        .filter((item) => item.type == "select")
        .map((item, index) => (
          <div key={index} className="search-bar-group">
            <label>{item.label}</label>
            <select
              className="search-bar-input"
              onChange={(e) =>
                searchForm.setFieldValue(item.name, e.target.value)
              }
              value={searchForm.values[item.name]}
            >
              {item.options?.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        ))}

      {searchItems
        .filter((item) => item.type == "text")
        .map((item, index) => (
          <div key={index} className="search-bar-group">
            <label>{item.label}</label>
            <input
              className="search-bar-input"
              type="search"
              placeholder={`${item.label}`}
              value={searchForm.values[item.name]}
              onChange={(e) =>
                searchForm.setFieldValue(item.name, e.target.value)
              }
            />
          </div>
        ))}

      <div className="search-bar-group">
        <button
          className="search-bar-input"
          onClick={() => {
            searchForm.handleSubmit();
          }}
        >
          {" "}
          Buscar <MdSearch style={{ float: "right", marginTop: 2 }} size={15} />
        </button>
      </div>
    </div>
  );
}

export { SearchBar };
