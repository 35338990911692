import React, { useEffect, useState } from "react";
import { CustomModal } from "../CustomModal";
import { useFormik } from "formik";
import { updateNotificationStatusApi } from "../../api/notification";
import { AiFillCalendar } from "react-icons/ai";
import { orderBy } from "lodash";

import {
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";

import * as Yup from "yup";
import "./index.css";
import Map from "../Map2";
import { NotificationMap } from "../NotificationMap";

export default function NotificationForm({
  open,
  setOpen,
  notificationData,
  setNotificationData,
  setRequestToogle,
  token,
}) {
  const handleClose = () => {
    setOpen(false);
    setNotificationData(false);
  };
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   (() => {
  //     notificationData.status_history.length > 0 &&
  //       notificationForm.setFieldValue(
  //         "eventStatus",
  //         notificationData.status_history[
  //           notificationData.status_history?.length - 1
  //         ].status.id
  //       );
  //   })();
  // }, [notificationData]);

  const notificationForm = useFormik({
    initialValues: {
      eventName: notificationData?.event?.name || "",
      eventStatus:
        (notificationData?.status_history &&
          orderBy(notificationData?.status_history, ["id"], ["desc"])[0].status
            .id) ||
        1,
      // eventStatus:
      //   (notificationData.status_history &&
      //     notificationData.status_history[
      //       notificationData.status_history?.length - 1
      //     ].status.id) ||
      //   1,
      comment: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object(valSchema(notificationData)),
    onSubmit: async (values, { resetForm }) => {
      try {
        let res = await updateNotificationStatusApi({
          id: notificationData?.id,
          status: values.eventStatus,
          comment: values.comment,
          token,
        });

        if (res) {
          if (res.detail) {
            alert(res.detail[0].msg);
          } else {
            setRequestToogle((state) => !state);
          }
        }

        // setOpen(false);
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  console.log(notificationData);
  const generalInfo = {
    Estado:
      (notificationData?.status_history &&
        notificationData?.status_history[0]?.status["verbose_name"]) ||
      "",
    "Fecha de entrada": notificationData?.created_at,
    "Activo Relacionado": notificationData?.unit?.name,
    "Nombre del evento": notificationData?.event?.name,
    Velocidad: notificationData?.unit_pos?.speed + " km/h",
  };

  const unitInfo = {
    "Tipo de Vehículo": notificationData?.unit?.profile?.vehicle_type || "",
    Marca: notificationData?.unit?.profile?.brand,
    Modelo: notificationData?.unit?.profile?.model,
    Color: notificationData?.unit?.profile?.color,
    Chasis: notificationData?.unit?.profile?.vin,
    Año: notificationData?.unit?.profile?.year,
    Placa: notificationData?.unit?.profile?.registration_plate,
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={"Notificación"}
      isLoading={isLoading}
      form={notificationForm}
      handleClose={handleClose}
    >
      <div className="NotificationForm-container">
        <div className="NotificationForm-box">
          <p className="NotificationForm-box-title">Información General</p>
          <div className="NotificationForm-box-content">
            {Object.entries(generalInfo).map((item, index) => (
              <div key={index} className="NotificationForm-box-content-item">
                <p>{item[0]}</p>
                <span
                  className={
                    item[0] == "Estado"
                      ? "NotificationForm-box-content-item--status"
                      : ""
                  }
                >
                  {item[1]}
                </span>
              </div>
            ))}
            <div className="NotificationForm-box-content-item">
              <p>Camiar estado</p>
              <select
                value={notificationForm.values.eventStatus}
                onChange={(e) =>
                  notificationForm.setFieldValue("eventStatus", e.target.value)
                }
              >
                {notificationData?.status_available?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.verbose_name}
                  </option>
                ))}
              </select>
              <textarea
                value={notificationForm.values.comment}
                onChange={(e) =>
                  notificationForm.setFieldValue("comment", e.target.value)
                }
                rows={2}
                placeholder="Comentario cambio de estado"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="NotificationForm-box">
          <p className="NotificationForm-box-title">
            Especificaciones del activo
          </p>
          <div className="NotificationForm-box-content">
            {Object.entries(unitInfo).map((item, index) => (
              <div key={index} className="NotificationForm-box-content-item">
                <p>{item[0]}</p>
                <span
                  className={
                    item[0] == "Estado"
                      ? "NotificationForm-box-content-item--status"
                      : ""
                  }
                >
                  {item[1]}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="NotificationForm-box NotificationForm-box--map">
          <NotificationMap
            style={{
              height: "300px",
              borderRadius: "4px",
            }}
            markerCoords={{
              lat: parseFloat(notificationData?.unit_pos?.latitude),
              lng: parseFloat(notificationData?.unit_pos?.longitude),
            }}
            customZoom={12}
            polygonCoords={notificationData?.event?.geofence.coordinates}
            polygonStyle={{
              backgroundColor: notificationData?.event?.geofence.color,
              borderColor: notificationData?.event?.geofence.color,
            }}
          />
        </div>
        <div className="NotificationForm-box">
          <p className="NotificationForm-box-title">Historial de estados</p>
          <div className="NotificationForm-box-content NotificationForm-box-content--history">
            {orderBy(notificationData?.status_history, ["id"], ["desc"])?.map(
              (item, index) => (
                <div
                  className="NotificationForm-box-content-item NotificationForm-box-content-item--history"
                  key={index}
                >
                  <div>
                    <AiFillCalendar
                      size={18}
                      style={{ marginRight: 4 }}
                      color="var(--main-bg-color)"
                    />
                    <span>{item.created_at}</span>
                  </div>
                  <p className="">
                    -<b>{item.status.verbose_name}</b> {item.comment} -{" "}
                    <b>{item.user}</b>
                  </p>
                </div>
              )
            )}
          </div>
        </div>
        {/* <div className="NotificationForm-box"></div> */}
      </div>
    </CustomModal>
  );
}

function valSchema(notificationData) {
  const messages = {
    required: "Favor complete este campo",
  };

  if (notificationData) return {};

  return {
    email: Yup.string()
      .email("El email no es válido!")
      .required(messages.required),

    eventName: Yup.string().required(messages.required),
    eventStatus: Yup.string().required(messages.required),
    password: Yup.string()
      .required(messages.required)
      .min(6, "* mínimo  6 caractéres"),
  };
}
