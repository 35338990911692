import React, { useEffect, useState } from "react";
import { TopBanner } from "../components/TopBanner";
import EventForm from "../components/EventForm";
import DataTable from "../components/Datatable";
import { getEventsApi } from "../api/event";
import useAuth from "../hooks/useAuth";
import "./css/EventScreen.css";
import { SearchBar } from "../components/SearchBar";

export default function EventScreen() {
  const { auth } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestToggle, setRequestToggle] = useState(false);
  const [targetPage, setTargetPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let events = await getEventsApi(
          auth.token,
          targetPage,
          pageSize,
          searchParams
        );
        setIsLoading(false);
        setData(events.results);
        setTotalRows(events?.info?.count);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [requestToggle, targetPage, pageSize, searchParams]);

  const columns = [
    {
      name: "Evento",
      selector: (row) => <span style={{ color: "#333333" }}>{row.name}</span>,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Creado",
      selector: (row) => row.created_at,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Estado",
      selector: (row) => row.active.toString(),
      sortable: true,
      selectable: true,
      reorder: true,
    },
  ];

  let searchedData = data?.filter((item) =>
    item.name.toLowerCase().includes(searchedText.toLowerCase())
  );

  const searchItems = [
    // {
    //   label: "Estado",
    //   name: "status",
    //   type: "select",
    //   options: [
    //     {
    //       label: "Activo",
    //       value: "active",
    //     },
    //   ],
    // },
  ];

  return (
    <div>
      <TopBanner
        sectionName="Eventos"
        bottonName="Nuevo evento"
        onClick={() => setOpen(true)}
      />

      <EventForm
        open={open}
        setOpen={setOpen}
        setRequestToggle={setRequestToggle}
      />

      <SearchBar
        searchItems={searchItems}
        mainFilter={"name"}
        setSearchParams={setSearchParams}
      />

      <div className="event_container-table">
        <DataTable
          columns={columns}
          data={searchedData}
          isLoading={isLoading}
          filterColName="name"
          filterPlaceholder={"evento"}
          totalRows={totalRows}
          onChangePage={(page) => setTargetPage(page)}
          onChangePageSize={(pageSize) => setPageSize(pageSize)}
        />
      </div>
    </div>
  );
}
