import React, { useEffect, useState, useRef, useCallback } from "react";
import Map from "../components/Map2";
import DataTable from "../components/Datatable";
import { getGeofencesApi } from "../api/geofence";
import { TopBanner } from "../components/TopBanner";
import GeofenceForm from "../components/GeofenceForm/GeofenceForm";
import useAuth from "../hooks/useAuth";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import { SearchBar } from "../components/SearchBar";

export default function GeofencesScreen() {
  const [drawing, setDrawing] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [geofenceColor, setGeofenceColor] = useState("");
  const [isUpdating, setisUpdating] = useState(false);
  const [requestToggle, setRequestToogle] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const createGeofenceApi = () => {
    setCoordinates([]);
    setDrawing(true);
    setOpen(false);
  };
  const { auth } = useAuth();
  const [targetPage, setTargetPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchParams, setSearchParams] = useState({});

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Acciones",
      cell: (row) => (
        <>
          <IconButton onClick={() => handleShowGeofence(row)}>
            {selectedRows.has(row) ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
          <IconButton onClick={() => handleEditGeofence(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let geofences = await getGeofencesApi(
          auth.token,
          targetPage,
          pageSize,
          searchParams
        );
        setIsLoading(false);
        setData(geofences.results);
        setTotalRows(geofences?.info?.count);
      } catch (error) {
        alert(error);
      }
    })();
  }, [auth.token, requestToggle, targetPage, pageSize, searchParams]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setCoordinates(nextPath);
    }
  }, [setCoordinates]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      if (path) {
        listenersRef.current.push(
          path.addListener("set_at", onEdit),
          path.addListener("insert_at", onEdit),
          path.addListener("remove_at", onEdit)
        );
      }
    },
    [onEdit]
  );

  const onClickMap = (event) => {
    if (!drawing) return;

    const { lat, lng } = event.latLng.toJSON();
    setCoordinates([...coordinates, { lat, lng }]);
  };

  const handleShowGeofence = (row) => {
    const updatedSelectedRows = new Set(selectedRows);

    if (updatedSelectedRows.has(row)) {
      updatedSelectedRows.delete(row);
    } else {
      updatedSelectedRows.add(row);
    }

    setSelectedRows(updatedSelectedRows);

    if (updatedSelectedRows.size > 0) {
      const selectedGeofences = Array.from(updatedSelectedRows);
      const paths = selectedGeofences.map((geofence) => geofence.coordinates);

      setGeofenceColor(row.color);
      setCoordinates(paths);
    } else {
      setCoordinates([]);
    }
  };

  const handleEditGeofence = (row) => {
    setDrawing(true);
    setCoordinates(row.coordinates);
    setSelectedRow(row);
    setGeofenceColor(row.color);
    setisUpdating(true);
  };

  return (
    <div className="geofence_container">
      {drawing ? (
        <div className="buttons-container">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <TopBanner
              onClick={() => setOpen(true)}
              isLoading={isLoading}
              bottonName="Guardar"
              style={{ marginLeft: "auto" }}
            />
          </div>
        </div>
      ) : (
        <TopBanner
          sectionName={"Geocercas"}
          onClick={createGeofenceApi}
          isLoading={isLoading}
          bottonName="Añadir Geocerca"
        />
      )}

      <div className="main">
        <div
          className="dash-mapView mb-4"
          // style={{
          //   backgroundColor: "var(--container-bg-color)",
          //   padding: 10,
          //   borderRadius: 20,
          // }}
        >
          <Map
            polygonColor={geofenceColor}
            polygonCoords={coordinates}
            polygonDrawing={drawing}
            onClick={onClickMap}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoad}
          />
        </div>
      </div>
      <SearchBar
        searchItems={[]}
        mainFilter={"name"}
        placeholder={"Buscar por nombre..."}
        setSearchParams={setSearchParams}
      />
      <div className="geofence_container">
        {/* {drawing ? (
          <div className="buttons-container">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TopBanner
                onClick={() => setOpen(true)}
                isLoading={isLoading}
                bottonName="Guardar"
                style={{ marginLeft: "auto" }}
              />
              
            </div>
          </div>
        ) : (
          <TopBanner
            sectionName={"Geocercas"}
            onClick={createGeofenceApi}
            isLoading={isLoading}
            bottonName="Añadir Geocerca"
          />
        )} */}
        <div className="geofence_container-table">
          <DataTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            filterColName="name"
            filterPlaceholder={"geocerca"}
            totalRows={totalRows}
            onChangePage={(page) => setTargetPage(page)}
            onChangePageSize={(pageSize) => setPageSize(pageSize)}
          />
        </div>
        <GeofenceForm
          open={open}
          setOpen={setOpen}
          vertices={coordinates}
          isUpdating={isUpdating}
          selectedRow={selectedRow}
          setData={setData}
          data={data}
          setDrawing={setDrawing}
          setSelectedRow={setSelectedRow}
          setRequestToogle={setRequestToogle}
          setPath={setCoordinates}
        />
      </div>
    </div>
  );
}
