import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { createGeofenceApi, updateGeofenceApi } from "../../api/geofence";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { CustomModal } from "../CustomModal";
import { TextField } from "@mui/material";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default function GeofenceForm({
  open,
  setOpen,
  setRequestToogle,
  vertices,
  selectedRow,
  setDrawing,
  isUpdating,
  setPath,
}) {
  const handleClose = () => {
    setOpen(false);
    setDrawing(false);
    setPath([]);
    setRequestToogle((state) => !state);
    GeofenceForm.resetForm();

    if (!isUpdating) {
      // Generate new random color
      const randomColor = getRandomColor();
      GeofenceForm.setFieldValue("color", randomColor);
    }
  };

  useEffect(() => {
    if (isUpdating && selectedRow) {
      GeofenceForm.setValues({
        name: selectedRow.name,
        color: selectedRow.color,
      });
    }
  }, [isUpdating, selectedRow]);
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useAuth();
  //const [isUpdating, setisUpdating] = useState(false);

  const GeofenceForm = useFormik({
    initialValues: {
      name: "",
      color: getRandomColor(),
    },
    validateOnChange: false,
    validationSchema: Yup.object({}),
    onSubmit: async (values, { resetForm }) => {
      let data = {
        name: values.name,
        color: values.color,
        coordinates: vertices,
      };

      try {
        setIsLoading(true);
        if (isUpdating) {
          await updateGeofenceApi(selectedRow.id, data, auth.token);
        } else {
          const response = await createGeofenceApi(data, auth.token);
        }
        setIsLoading(false);
        resetForm();
        handleClose();
      } catch (error) {
        console.log(error);
        setOpen(false);
      }
    },
  });

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="GEOCERCAS"
      form={GeofenceForm}
      handleClose={handleClose}
    >
      <form>
        <div className="form-group">
          <label>Nombre</label>
          <TextField
            autoFocus
            className="form-control my-4"
            label="Nombre (s)"
            variant="outlined"
            color="warning"
            value={GeofenceForm.values.name}
            onChange={(e) => GeofenceForm.setFieldValue("name", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Color</label>
          <input
            type="color"
            className="form-control"
            placeholder=""
            value={GeofenceForm.values.color}
            onChange={(e) =>
              GeofenceForm.setFieldValue("color", e.target.value)
            }
          />
        </div>
        <div className="form-group text-center">
          {!isUpdating && (
            <button
              type="submit"
              onClick={GeofenceForm.handleSubmit}
              className="btn btn-primary"
              style={{
                backgroundColor: "#50a4bd",
                borderColor: "#50a4bd",
                fontWeight: "bold",
              }}
            >
              Crear
            </button>
          )}
          {isUpdating && (
            <button
              type="submit"
              onClick={GeofenceForm.handleSubmit}
              className="btn btn-primary"
              style={{
                backgroundColor: "#50a4bd",
                borderColor: "#50a4bd",
                fontWeight: "bold",
              }}
            >
              Guardar Cambios
            </button>
          )}
        </div>
      </form>
    </CustomModal>
  );
}
