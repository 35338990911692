import React, { useEffect, useState } from "react";
import { TopBanner } from "../components/TopBanner";
import EventForm from "../components/EventForm";
import DataTable from "../components/Datatable";
import { getNotificationsApi } from "../api/notification";
import useAuth from "../hooks/useAuth";
import "./css/EventScreen.css";
import NotificationsMenu from "../components/NotifactionsMenu";
import NotificationForm from "../components/NotificationForm";
import { createClient } from "@supabase/supabase-js";
import { supabaseId, supabaseKeyAnon } from "../utils/constants";
import { orderBy, uniqBy } from "lodash";
import { SearchBar } from "../components/SearchBar";

export default function NotificationScreen() {
  const { auth } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [notificationData, setNotificationData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestToggle, setRequestToggle] = useState(false);
  const [targetPage, setTargetPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [payloadRealtime, setPayload] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [selectedId, setSelectedId] = useState(0);

  const client = createClient(
    `https://${supabaseId}.supabase.co`,
    supabaseKeyAnon
  );

  // client
  //   .channel("table-db-changes")
  //   .on(
  //     "postgres_changes",
  //     {
  //       event: "INSERT",
  //       schema: "public",
  //       table: "notification_history",
  //     },
  //     (payload) => {
  //       setPayload(payload);
  //     }
  //   )
  //   .subscribe();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let notifications = await getNotificationsApi(
          auth.token,
          searchParams,
          targetPage,
          pageSize
        );
        setIsLoading(false);

        let parsedData = [];

        notifications?.results.map((n, index) => {
          parsedData.push({
            created_at: n.created_at,
            eventName: n.event.name,
            event: n.event,
            unitName: n.unit.name,
            unit: n.unit,
            unitPostion: n.unit_pos,
            status: orderBy(n.status_history, ["id"], ["desc"])[0]?.status
              .verbose_name,
            availableStatus: n.status_available,
            action: (
              <NotificationsMenu
                data={notifications.results}
                open={open}
                setOpen={setOpen}
                id={n.id}
                notificationData={notificationData}
                setNotificationData={setNotificationData}
                setSelectedId={setSelectedId}
              />
            ),
          });
        });

        let currentIndex = notifications?.results.findIndex(
          (item) => item.id == selectedId
        );

        console.log(parsedData);
        setNotificationData(notifications?.results[currentIndex]);
        setData(parsedData);
        setTotalRows(notifications?.info?.count);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [
    auth.token,
    requestToggle,
    targetPage,
    pageSize,
    payloadRealtime,
    searchParams,
  ]);

  const columns = [
    {
      name: "Fecha",
      selector: (row) => row.created_at,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Evento",
      selector: (row) => row.eventName,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Unidad",
      selector: (row) => row.unitName,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Estado",
      selector: (row) => row.status,
      sortable: true,
      selectable: true,
      reorder: true,
    },
    {
      name: "Acciones",
      selector: (row) => row.action,
    },
  ];

  const searchItems = [
    // {
    //   label: "Unidad",
    //   name: "unit",
    //   type: "text",
    // },
  ];

  // let searchedData = data.filter((item) =>
  //   item.eventName.toLowerCase().includes(searchedText.toLowerCase())
  // );

  return (
    <div>
      <TopBanner sectionName="Notificaciones" />

      {/* <EventForm
        open={open}
        setOpen={setOpen}
        setRequestToggle={setRequestToggle}
      /> */}

      <SearchBar
        searchItems={searchItems}
        mainFilter={"eventName"}
        // searchedText={searchedText}
        // setSearchedText={setSearchedText}
        setSearchParams={setSearchParams}
        // setRequestToggle={setRequestToggle}
        // requestToggle={requestToggle}
      />

      <div className="event_container-table">
        <DataTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          filterColName="created_at"
          filterPlaceholder={"notificación"}
          totalRows={totalRows}
          onChangePage={(page) => setTargetPage(page)}
          onChangePageSize={(pageSize) => setPageSize(pageSize)}
        />
      </div>
      <NotificationForm
        open={open}
        setOpen={setOpen}
        setRequestToogle={setRequestToggle}
        requestToggle={requestToggle}
        token={auth.token}
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
    </div>
  );
}
